export default (() => {
  class AdblockPlus {
    constructor() {
      this.detected = false
      this.checksRemaining = 2
      this.errors = []
    }

    detect(px, cb) {
      if (typeof cb !== 'function') {
        return
      }

      const pxTemplate = `${px}?ch=[[ch_placeholder]]&rn=[[rn_placeholder]]`
      const random = Math.random() * 11

      for (let i = 1; i <= this.checksRemaining; i++) {
        this.createImage(i, random, pxTemplate)
      }

      this.beforeCheck(cb, 250)
    }

    beforeCheck(callback, timeout) {
      if (this.checksRemaining === 0 || timeout > 1000) {
        callback(this.checksRemaining === 0 && this.detected)
      } else {
        setTimeout(() => {
          this.beforeCheck(callback, timeout * 2)
        }, timeout * 2)
      }
    }

    checkImages() {
      this.checksRemaining -= 1
      if (this.checksRemaining) {
        return
      }
      this.detected = this.errors.length === 1
    }

    createImage(i, random, px) {
      const img = document.createElement('img')
      img.addEventListener('load', () => this.checkImages())
      img.addEventListener('error', () => {
        this.errors.push(true)
        this.checkImages()
      })
      img.src = px.replace('[[ch_placeholder]]', i).replace('[[rn_placeholder]]', random)
    }
  }

  return AdblockPlus
})()
