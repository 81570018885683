import AdblockPlus from './adblock_plus'
import { rapidReady } from '../../../../lib/utils/rapid'
import { dispatchCustomEvent } from '../../../../lib/utils/events'

const NOT_BLOCKED = '0'
const BLOCKED_NO_ABP = '1'
const BLOCKED_ABP = '2'
const THAMBA_AD_ID = 'advertisement-thamba'
const CLASS_ABP = 'isABP'
export default (() => {
  class AdBlocker {
    constructor({ selector }) {
      this.elem = document.querySelector(selector)
      if (!this.elem) {
        return
      }

      try {
        this.config = JSON.parse(this.elem.dataset.config)
      } catch (e) {
        throw new Error('Error Parsing Adblocker Config')
      }
      this.utils = window.NEXUtils || {}
      this.abSelector = document.getElementById(THAMBA_AD_ID)
      this.thamba = this.config.thamba === BLOCKED_NO_ABP || this.config.thamba === BLOCKED_ABP

      if (this.config.thamba === BLOCKED_ABP) {
        document.documentElement.classList.add(CLASS_ABP)
      }
      setTimeout(this.init.bind(this), this.config.dabConfig.detectionTimeout || 300)
    }

    init() {
      let daTrk = NOT_BLOCKED
      let cExpiry = 0

      /* General Adblock check */
      if (!this.abSelector) {
        daTrk = BLOCKED_NO_ABP
        cExpiry = this.config.cookieDuration
      }

      /* Specific Adblock check */
      if (this.config.dabConfig.enableUBDetection) {
        const isUBActive = this.detectUB()
        if (isUBActive) {
          daTrk = BLOCKED_NO_ABP
          cExpiry = this.config.cookieDuration
        }
      }

      /** conditions
       * - cookie is set and we have to clear cookie (blocked state -> non-blocked state)
       * - cookie is not set and we have to set cookie (non-blocked state -> blocked [not ABP])
       */
      if ((this.thamba && daTrk === NOT_BLOCKED) || (!this.thamba && daTrk === BLOCKED_NO_ABP)) {
        this.utils.setCookie(this.config.cookieName, daTrk, cExpiry)
        // Cookie was set, but the user now no longer has abp enabled
        if (this.thamba && daTrk === NOT_BLOCKED) {
          document.documentElement.classList.remove(CLASS_ABP)
          dispatchCustomEvent('GAM:enableAdBlock', '')
          rapidReady(rapid => {
            rapid.setRapidAttribute({ keys: { abk: '' } })
          })
        }
      }

      if (this.config.dabConfig.enableABPDetection && daTrk === BLOCKED_NO_ABP) {
        this.detectABP(daTrk)
      }
    }

    detectABP(daTrk) {
      const abp = new AdblockPlus()
      abp.detect(this.config.pxImg, usesABP => {
        if (usesABP) {
          daTrk = BLOCKED_ABP

          // Only set cookie if it's not already set to avoid overwritting cookie duration
          if (this.config.thamba !== BLOCKED_ABP) {
            this.utils.setCookie(this.config.cookieName, daTrk, this.config.cookieDuration)
          }
        }
        // handle cases when switching from ABP to Non-ABP
        else if (this.config.thamba !== BLOCKED_NO_ABP) {
          this.utils.setCookie(this.config.cookieName, daTrk, this.config.cookieDuration)
        }

        if (!this.thamba && daTrk !== NOT_BLOCKED) {
          const abk = daTrk === '2' ? 'abp' : 'other'
          dispatchCustomEvent('GAM:enableAdBlock', abk)
          rapidReady(rapid => {
            rapid.setRapidAttribute({ keys: { abk } })
            rapid.beaconEvent('daTrk', { pl1: daTrk, abk })
          })
        }
      })
    }

    detectUB() {
      const decoyId = 'my-ads'
      const decoyNode = document.createElement('div')
      decoyNode.setAttribute('id', decoyId)
      decoyNode.innerText = decoyId
      document.body.appendChild(decoyNode)

      const decoyNodeIns = document.getElementById(decoyId)
      if (decoyNodeIns && decoyNodeIns.offsetHeight === 0) {
        decoyNodeIns.remove()
        return true
      }
      decoyNodeIns.remove()

      // additional check: check all necessary ad pos dimension
      if (this.config.shouldCheckPosition) {
        const { positions } = this.config.dabConfig
        let adposNodeCount = 0
        let hiddenPosNodeCount = 0

        positions.forEach(position => {
          const adposNode = document.getElementById(position)
          if (adposNode) {
            adposNodeCount += 1
            if (adposNode.offsetHeight === 0) {
              hiddenPosNodeCount += 1
            }
          }
        })

        if (adposNodeCount > 0 && adposNodeCount === hiddenPosNodeCount) {
          return true
        }
      }

      return false
    }
  }

  return AdBlocker
})()
